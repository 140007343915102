<!--
 * @Author: HZH
 * @Date: 2021-09-16 14:16:01
 * @LastEditors: HZH
 * @LastEditTime: 2021-09-18 09:37:26
 * @Description: 
-->
<template>
	<div>
		<div class="home">
			<!-- <Head></Head> -->
			<div class="content">
				<div class="wrapper">
					<div class="product_title">隐私政策</div>
					<div>

						<div
							style="font-size: 13px; color: rgb(51, 51, 51); line-height: 22px; overflow-wrap: break-word;">
							本政策仅适用于中爱一生网络科技(成都)有限公司的中爱一生产品或服务。 最近更新日期：2023年07月10日开始生效<br>
							本政策将帮助您了解以下内容：<br> 一、我们如何收集和使用您的用户信息<br> 二、我们如何使用
							Cookie 和同类技术<br> 三、我们如何共享、转让、公开披露您的用户信息<br>
							四、我们如何保护您的用户信息<br> 五、您的权利<br> 六、本政策如何更新<br data-v-77bc7c6d=""> 七、如何联系我们<br> 八、我们需要获取的权限<br>
							九、获取的SDK<br>
							十、儿童隐私政策<br>
							我们深知用户信息对您的重要性，并会尽全力保护您的用户信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的用户信息
							：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按
							业界成熟的安全标准，采取相应的安全保护措施来保护您的用户信息。 请在使用我们的产品（或服务）前，仔细阅读并了解本隐私政策。<br>
							一、我们如何收集和使用您的用户信息<br> 我们如何收集和使用信息<br> （1）注册登录<br data-v-77bc7c6d=""> a.在您注册（中爱一生)
							账号时，您需要至少向我们提供您本人的手机号码，我们将通过发送短信验证码的方式来验证您的身份是否有效，您还可以根据自身需求选择填写性别、生日及个性化签名，修改或补充您的头像、昵称来完善您的信息。您补充的账户信息将有助于我们为您提供更优的服务体验，包括展示您想要（中爱一生)
							展示的头像和昵称。但如果您不提供这些补充信息，不会影响您使用（中爱一生) 的基本功能。<br>
							b.您也可以使用第三方帐号登录并使用，您将授权我们获取您在第三方平台注册的公开信息（头像、昵称以及您授权的其他信息），用于与（中爱一生)
							帐号绑定，使您可以直接登录并使用本产品和相关服务。<br> c.基于我们与通信运营商的合作，当您使用（中爱一生）
							本机号码登录”功能时，经过您的明示同意，运营商会将您的手机号码发送给我们，便于我们为您提供快捷的登录服务。手机号码属于个人敏感信息，如果拒绝提供将无法使用“本机号码登录”方式注册登录（中爱一生)
							，但不影响您通过其他方式注册登录，也不影响其他功能的正常使用。<br> d.基于位置信息的服务功能：<br>
							地理位置信息：GPS信息、WLAN接入点、蓝牙和基站传感器信息。<br> 我们会收集您的 位置信息
							（我们仅收集您当时所处的地理位置，但不会将您各时段的位置信息进行结合以判断您的行踪轨迹）来判断您所处的地点，为您推荐您所在城市的地方考题和有关资讯。（中爱一生)
							在您的设备使用定位服务时不会收集其他无关信息。<br>
							上述附加功能可能需要您在您的设备中向我们开启您的地理位置（位置信息）、相册（图片库）、麦克风（录音权限），以实现这些功能所涉及的信息的收集和使用。<br
								data-v-77bc7c6d="">
							我们将在您首次下载APP或者具体使用到上述所列功能时逐一以弹窗的形式提前向您提示，您可以根据您的需要进行选择。您可在您设备的“设置-应用-权限管理”路径，查看、关闭上述系统权限，在不同的设备或系统版本中，相关查看或操作路径和方式可能有所不同，具体请参考设备及系统开发方说明或指引。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能，但您仍可以使用除此之外的其他（中爱一生)
							服务。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。<br> e.个性化服务通知：<br>
							为了使我们更加了解您如何接入和使用我们的服务，从而针对性的回应您的个性化需求，例如语言设定、个性化的帮助服务和指示等，我们会有不定时的个性化服务通知，如需关闭此项通知消息，可点击“我的-我的设置-隐私设置-个性化推荐”按钮进行开启或者关闭。<br
								data-v-77bc7c6d=""> （2）消息通知<br>
							您知悉并同意，对于您在使用产品与/或服务的过程中提供的您的联系方式（联系电话），我们在运营中可能会向其中的一种或多种发送多类通知，用于用户消息告知、身份验证、安全验证用途。如您不愿意接受这些信息，您可以通过手机短信中提供的退订方式进行退订，也可以直接与我们联系进行退订。<br
								data-v-77bc7c6d=""> （3）运营与安全保障<br>
							为保障您正常使用我们的服务，维护我们服务的正常运行，我们会收集您的设备信息和日志信息，用以改善及优化您的服务体验并保障您的账号安全。<br>
							a.为了保障软件服务的安全、运营的质量及效率，我们可能会收集您的硬件型号、操作系统版本号、国际移动设备识别码、唯一设备标识符【MAC地址信息、imei、imsi或oaid、Android
							Id 、CPU ID 序列号、OpenUUId
							(iOS)】、网络设备硬件地址、IP地址、WLAN接入点、蓝牙、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志。该信息的收集用于保障您的红鸾坊账号安全、防盗号、信息安全的功能，我们不会对上述信息作其他用途使用；<br
								data-v-77bc7c6d="">
							b.为了预防恶意程序、确保运营质量及效率，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。<br
								data-v-77bc7c6d="">
							c.我们可能使用您的账户信息、设备信息、服务日志信息以及我们关联方、合作方在获得您授权或依法可以共享的信息，用于判断账户状态、身份验证、检测及防范安全事件。<br
								data-v-77bc7c6d=""> （4）当您参加（中爱一生)
							的有关营销活动时，我们会收集您的姓名、通讯地址、联系方式、银行账号的信息。这些信息是您收到转账或礼品的基础，如果您拒绝提供这些信息，我们将无法向您转账或发放礼品（如有）。<br
								data-v-77bc7c6d=""> （5）支付功能<br> 您可在（中爱一生)
							进行达人课程服务购买，我们会收集您的收集如下部分或全部的个人信息，包括：交易商品或服务信息、收货人信息（收货人姓名、联系电话）（个人敏感信息）、交易金额、下单时间、订单商户、订单编号、订单状态、支付方式、支付账号、支付状态（个人敏感信息），我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客户服务等。在支付过程中，我们可能会收集您的
							第三方支付账号(包括Apple
							Store账号、支付宝账号、微信账号)，以实现原路径退款、售后服务。我们会根据您选择的支付方式把您的订单支付相关信息、订单安全相关设备信息及其他反洗钱法律要求的必要信息通过在应用程序中嵌入的支付宝以及其他第三方支付的软件工具开发包（SDK）的方式与支付宝公司或其他第三方支付公司共享。<br
								data-v-77bc7c6d=""> （6）申请手机权限的使用情况说明<br>
							特别需要指出的是，即使经过您的授权，我们获得了这些敏感权限，也不会在相关功能或服务不需要时收集您的信息。我们将在您首次下载APP或者具体使用到上述所列功能时逐一以弹窗的形式提前向您提示，您可以根据您的需要进行选择。您可在您设备的“设置-应用-权限管理”路径，查看、关闭上述系统权限，在不同的设备或系统版本中，相关查看或操作路径和方式可能有所不同，具体请参考设备及系统开发方说明或指引请您注意。关闭权限后，只会影响当前功能的使用，但不影响蒸了么其它功能的正常使用。索未来<br
								data-v-77bc7c6d="">
							基于对您信息的有效保护并且在您同意的基础上，我们会对收集到的您的信息进行去标签化处理，以保护您的信息安全。在此希望您了解并接受在不透露您个人信息的前提下，（中爱一生)
							有权对您的信息进行分析并予以商业化的利用。<br> （二）征得授权同意的例外<br>
							请您理解，根据法律法规及相关国家标准，以下情形中，我们收集和使用您的用户信息无需征得您的授权同意： <br> 1、与国家安全、国防安全直接相关的；
							<br> 2、与公共安全、公共卫生、重大公共利益直接相关的；<br>
							3、与犯罪侦查、起诉、审判和判决执行等直接相关的； <br> 4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； <br data-v-77bc7c6d="">
							5、所收集的您的用户信息是您自行向社会公众公开的；<br>
							6、从合法公开披露的信息中收集的您的用户信息，如合法的新闻报道、政府信息公开等渠道； <br> 7、根据您的要求签订或履行合同所必需的； <br data-v-77bc7c6d="">
							8、用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障；<br>
							9、 个人信息控制者为新闻单位且其在开展合法的新闻报道所必需的； <br>
							10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处 理的。<br>
							11、法律法规规定的其他情形。<br> 二、我们如何使用 Cookie 和同类技术<br>
							（一）Cookie<br> 为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie
							通常包含标识符、站点名称以及一些号码和字 符。借助于 Cookie，网站能够存储您的访问偏好数据。 我们不会将 Cookie
							用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机上保存的所有 Cookie， 大部分网络浏览器都设有阻止Cookie
							的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。<br> （二）网站信标和像素标签<br data-v-77bc7c6d=""> 除 Cookie
							外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点
							击URL。 如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中
							的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我 们的寄信名单中退订。<br
								data-v-77bc7c6d=""> （三）Do Not Track（请勿追踪）<br> 很多网络浏览器均设有Do Not
							Track功能，该功能可向网站发布Do Not Track请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应 如何应对此类请求。但如果您的浏览器启用了 Do Not
							Track，那么我们的所有网站都会尊重您的选择。<br> 三、我们如何共享、转让、公开披露您的用户信息<br data-v-77bc7c6d=""> （一）共享<br>
							我们不会与其他的任何公司、组织和个人分享您的用户信息，但以下情况除外：
							1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的用户信息。<br>
							2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的用户信息。<br>
							3、与我们的关联公司共享：您的用户信息可能会与我们的关联公司共享。我们只会共享必要的用户信息，且受本隐私政策中所声明目的的约束。
							关联公司如要改变用户信息的处理目的，将再次征求您的授权同意。<br>
							4、与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些用户信息
							，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的用户信息，并且只会共享提供服务所必要的
							用户信息。为了更好运营和改善技术和服务，您同意我们和授权合作伙伴在符合相关法律法规的前提下可将收集的信息用于其他服务和用途。<br>
							对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保 密和安全措施来处理用户信息。<br
								data-v-77bc7c6d=""> （二）转让<br> 我们不会将您的用户信息转让给任何公司、组织和个人，但以下情况除外：<br data-v-77bc7c6d="">
							1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的用户信息；<br>
							2、在涉及合并、收购或破产清算时，如涉及到用户信息转让，我们会再要求新的持有您用户信息的公司、组织继续受此隐私政策的约束，否则我 们将要求该公司、组织重新向您征求授权同意。<br
								data-v-77bc7c6d=""> （三）公开披露<br> 我们仅会在以下情况下，公开披露您的用户信息：<br data-v-77bc7c6d="">
							1、获得您明确同意后；<br>
							2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的用户信息。<br>
							（四）共享、转让、公开披露信息时事先征得授权同意的例外<br>
							请您理解，根据法律法规及相关国家标准，以下情形中，我们共享、转让、公开披露您的用户信息无需征得您的授权同意：<br>
							1、与国家安全、国防安全直接相关的； <br> 2、与公共安全、公共卫生、重大公共利益直接相关的； <br>
							3、与犯罪侦查、起诉、审判和判决执行等直接相关的； <br> 4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br data-v-77bc7c6d="">
							5、您自行向社会公众公开的信息； <br>
							6、从合法公开披露的信息中收集的，如合法的新闻报道、政府信息公开等渠道。<br> 四、我们如何保护您的用户信息<br data-v-77bc7c6d="">
							（一）我们已使用符合业界标准的安全防护措施保护您提供的用户信息，防止数据遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。
							我们会采取一切合理可行的措施，保护您的用户信息。例如，在您的浏览器与“服务”之间交换数据时受 SSL 加密保护；我们同时对网站提供
							https安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，
							确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。<br>
							（二）我们会采取一切合理可行的措施，确保未收集无关的用户信息。我们只会在达成本政策所述目的所需的期限内保留您的用户信息，除非需 要延长保留期或受到法律的允许。<br
								data-v-77bc7c6d=""> （三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送用户
							信息。<br> （四）互联网环境并非百分之百安全，我们将尽力确保您发送给我们的任何信息的安全性。即使我们做出了很大努力，采取了一切合理且必要的
							措施，仍然有可能无法杜绝您的用户信息被非法访问、被非法盗取，被非法篡改或毁坏，导致您的合法权益受损，请您理解信息网络的上述风险 并自愿承担。<br
								data-v-77bc7c6d=""> （五）在不幸发生用户信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采
							取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您
							，难以逐一告知用户信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报用户信息安全事件的处 置情况。<br>
							五、您的权利<br> 按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的用户信息行使以下权利：<br data-v-77bc7c6d="">
							（一）访问您的用户信息<br>
							您有权访问您的用户信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：补充用户信息访问方式 如果您无法通过上述链接访问这些用户信息，您可以随时使用我们的
							Web 表单联系，或发送电子邮件至za13808215296@163.com。我们将在30天内回复 您的访问请求。
							对于您在使用我们的产品或服务过程中产生的其他用户信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请发送电 子邮件至za13808215296@163.com<br
								data-v-77bc7c6d=""> （二）更正您的用户信息<br>
							当您发现我们处理的关于您的用户信息有错误时，您有权要求我们作出更正。您可以通过“（一）访问您的用户信息”中罗列的方式提出更正申 请。
							如果您无法通过上述链接更正这些用户信息，您可以随时使用我们的 Web 表单联系，或发送电子邮件至za13808215296@163.com。我们将在30天 内回复您的更正请求。<br
								data-v-77bc7c6d=""> （三）删除您的用户信息<br> 在以下情形中，您可以向我们提出删除用户信息的请求：<br data-v-77bc7c6d="">
							1、如果我们处理用户信息的行为违反法律法规；<br>
							2、如果我们收集、使用您的用户信息，却未征得您的同意；<br> 3、如果我们处理用户信息的行为违反了与您的约定；<br data-v-77bc7c6d="">
							4、如果您不再使用我们的产品或服务，或您注销了账号；<br>
							5、如果我们不再为您提供产品或服务。<br>
							我们将会根据您的删除请求进行评估，若满足相应规定，我们将会采取相应步骤进行处理。当您向我们提出删除请求时，我们可能会要
							求您进行身份验证，以保障账户的安全。当您从我们的服务中删除信息后，因为适用的法律和安全技术，我们可能不会立即从备份系统中删除相
							应的信息，我们将安全存储您的信息直到备份可以清除或实现匿名化。<br> （四）注销帐号<br>
							您随时可以注销此前注册的账号，在账户注销后，原账户相关信息会被清空且无法找回且无法进行以下操作：<br>
							1、账号中的个人信息、历史信息、书架记录等将被清空；<br> 2、账号中的权益将被取消，已购买会员产品将视为自动放弃；<br data-v-77bc7c6d="">
							3、账号中的邀请好友关系将被清空；<br>
							注销账号方式：联系客服工作号，联系人工客服。或者发邮件给za13808215296@163.com。一般审核时间为1-2天。<br>
							（五）响应您的上述请求<br>
							为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。 我们将在三十天内作出答复。<br
								data-v-77bc7c6d=""> 对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要
							过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
							也请您理解，出于安全保障的考虑、相关法律法规的要求或技术上的限制，对于您的某些请求我们可能无法做出响应，例如以下情形：<br>
							1、与用户信息控制者履行法律法规规定的义务相关的；<br> 2、与国家安全、国防安全直接相关的；<br>
							3、与公共安全、公共卫生、重大公共利益直接相关的；<br> 4、与犯罪侦查、起诉、审判和执行判决等直接相关的；<br data-v-77bc7c6d="">
							5、用户信息控制者有充分证据表明用户信息主体存在主观恶意或滥用权利的；<br>
							6、出于维护用户信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br>
							7、响应用户信息主体的请求将导致用户信息主体或其他个人、组织的合法权益受到严重损害的； <br> 8、涉及商业秘密的。<br data-v-77bc7c6d="">
							六、本政策如何更新<br>
							我们的隐私政策可能变更。未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更 。<br>
							对于重大变更，我们还会提供更为显著的通知。本政策所指的重大变更包括但不限于：<br>
							1、我们的服务模式发生重大变化。如处理用户信息的目的、处理的用户信息类型、用户信息的使用方式等；<br>
							2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；<br>
							3、用户信息共享、转让或公开披露的主要对象发生变化；<br> 4、您参与用户信息处理方面的权利及其行使方式发生重大变化；<br data-v-77bc7c6d="">
							5、我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时；<br>
							6、用户信息安全影响评估报告表明存在高风险时。<br> 我们还会将本政策的旧版本存档，供您查阅。<br>
							七、如何联系我们<br> 如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：<br>
							电子邮件：za13808215296@163.com<br> 电 话：13558270114<br>
							联系地址：浦北县小江镇越兴街金浦华苑锦·绣园绣C幢07号<br>
							（六）我们设立了用户信息保护专职部门，您可以通过邮件za13808215296@163.com联系我们<br> 一般情况下，我们将在三十天内回复。<br data-v-77bc7c6d="">
							八、我们需要获取的权限<br> 为了用户能完整体验我们的所有功能，我们需要您授权以下权限<br data-v-77bc7c6d="">
							1、需要访问通讯录联系人用来实现屏蔽通讯录好友<br>
							2、需要获取用户大概位置信息以推送附近的用户展示给用户浏览<br> 3、发送短信，用户注册或登录时需要的验证码<br data-v-77bc7c6d="">
							4、录音，语音通话，聊天<br> 5、相机用于私聊给对方拍摄视频<br data-v-77bc7c6d=""> 九、获取的SDK<br> 极光推送<br>
							使用目的：帮助提升推送消息触达和保障系统运行稳定<br> 数据类型：未共享数据<br>
							官网链接：https://docs.jiguang.cn/jpush/guideline/intro/<br> 华为 PUSH<br data-v-77bc7c6d="">
							使用目的：推送消息<br> 数据类型：设备标识信息<br>
							官网链接：https://developer.huawei.com/consumer/cn/hms/huawei-pushkit<br> QQ分享
							SDK<br> 使用目的：帮助用户分享内容至第三方应用<br> 数据类型：设备标识信息<br data-v-77bc7c6d="">
							官网链接：https://open.tencent.com/<br> 穿山甲SDK<br data-v-77bc7c6d=""> 使用目的：向用户推送广告信息、反作弊<br>
							收集数据类型：设备识别信息、应用信息、网络信息<br data-v-77bc7c6d=""> 提供主体：北京巨量引擎网络技术有限公司<br>
							隐私政策：https://www.pangle.cn/privacy/partner<br>
							十、儿童隐私政策
							<br>
							1、儿童监护人特别说明：

							我们希望请您与我们携手，共同保护儿童的个人信息，教育引导儿童增强个人信息保护意识和能力，确保您监护的儿童在您的允许或指导下使用我们的产品及服务和向我们提供信息。我们将根据本声明采取特殊措施保护我们处理的儿童个人信息。<br>
							2、中爱一生如何保护儿童个人信息<br>
							
							我们非常重视儿童的隐私安全，我们会采取加密、匿名化、假名化等技术措施传输和存储儿童个人信息，确保信息安全。
							
							我们会默认保护儿童隐私，当我们的产品或服务识别为儿童使用时，会自动禁止个性化广告、直接营销功能，并提供儿童适宜的内容；出于为儿童提供个性化体验、为改进我们的服务目的使用儿童个人信息前会征得您的同意。为帮助您保护您监护的儿童，我们还提供了一些家长控制功能，包括“访问限制”、“使用时间管理”、“购买前询问”等，您可设置儿童使用各应用的时长/时段，对特定应用/服务的访问，基于年龄段的内容访问，批准或拒绝儿童的支付操作等来更好的保护您监护的儿童。
							
							我们会严格控制儿童个人信息的访问权限，对可能接触到儿童个人信息的工作人员采取最小必要授权原则，并采取技术措施对工作人员处理行为进行记录和管控，避免违法复制、下载儿童个人信息。
							<br><br><br>
							如您同意以上协议内容，请点击“同意并继续”，
							并表示您充分理解并同意协议条款内容，欢迎您开始使用我们的产品和服务。
						</div>
					</div>

				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		get
	} from "../utils/request";
	import Head from './components/Head.vue'
	import Foot from './components/Foot.vue'
	export default {
		// 使用注册组件
		components: {
			// 1.引入所需组件

			// 2.注册组件
			Head,
			Foot
		},
		data() {
			return {
				// message: "hello",
				swipe: [{
						"id": 1,
						"url": "https://zhongaiyisheng.oss-cn-beijing.aliyuncs.com/uploads/images/202307101021007d3305321.png",
					},
					{
						"id": 1,
						"url": "https://zhongaiyisheng.oss-cn-beijing.aliyuncs.com/uploads/images/202307101021007d3305321.png",
					}
				],

			};
		},
		methods: {},
		created() {},
	};
</script>

<style lang="scss" scoped>
	.content {
		.wrapper {
			width: 85%;

			.plates {
				display: flex;
				flex-wrap: wrap;
				width: 100%;

				.plate {
					display: flex;
					width: 25%;
					padding: 10px;
					box-sizing: border-box;
					position: relative;
					margin-bottom: 30px;

					.plate_img {
						display: block;
						width: 100%;
						height: 100%;
						border-top-left-radius: 20px;
						border-top-right-radius: 20px;
					}

					.plate_title {
						margin-top: 15px;
					}
				}
			}
		}

		.product_title {
			font-size: 30px;
			font-weight: 600;
			margin: 20px;
		}
	}

	.footer {
		height: 200px;
		// background-color: #528c38;
	}
</style>